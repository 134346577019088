import React from 'react'
import { navigate } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Container from '../components/Container'
import { Wrapper } from '../components/Wrapper'
import styled from 'styled-components'
import { STYLES } from '../styles/constants'
import { Button, buttonThemes } from '../components/Button'

const MessageContainer = styled.div`
  text-align: center;
  padding: 24px 16px;
  min-height: calc(100vh - ${STYLES.HEIGHT_HEADER_MB} - ${STYLES.HEIGHT_FOOTER});
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-family: "Montserrat";
    font-weight: ${STYLES.FW_BOLD};
    font-size: 24px;
    color: #FFFFFF;
    line-height: 40px;
  }

  button {
    margin-top: 14px;
  }

  @media (min-width: ${STYLES.BP_MEDIUM_MIN}) {
    min-height: calc(100vh - ${STYLES.HEIGHT_HEADER_DT} - ${STYLES.HEIGHT_FOOTER});
    padding-top: 72px;

    h1 {
      margin-bottom: 8px;
    }
  }
`;

class NotFound extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    return (
      <Container>
        <Helmet title={`Page Not Found | P+N Showcase`}>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <Wrapper>
          <MessageContainer>
            <h1>Oops, this page doesn't exist.</h1>
            <Button theme={buttonThemes.primary} onClick={() => navigate('/')}><span>Go Home</span></Button>
          </MessageContainer>
        </Wrapper>
      </Container>
    )
  }
}

export default NotFound